<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header card-header-primary card-header-icon">
            <div class="card-icon">
              <i class="material-icons">remove_red_eye</i>
            </div>
            <h4 class="card-title">
              <strong>{{ $t("data.asset.title_singular") }}</strong>
            </h4>
          </div>
          <div class="card-body">
            <bootstrap-alert />
            <back-button></back-button>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="table-overlay" v-show="loading">
                  <div class="table-overlay-container">
                    <material-spinner></material-spinner>
                    <span>{{ $t("global.loading") }}</span>
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table table-hover">
                    <tbody>
                      <tr></tr>
                      <tr>
                        <th scope="row" class="text-primary">
                          {{ $t("data.asset.fields.id") }}
                        </th>
                        <td>
                          {{ asset.id }}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" class="text-primary">
                          {{ $t("data.asset.fields.name") }}
                        </th>
                        <td>
                          {{ asset.name }}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" class="text-primary">
                          {{ $t("data.asset.fields.sequential_number") }}
                        </th>
                        <td>
                          {{ asset.sequential_number }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div>
              <button
                class="btn"
                :class="
                  asset.mode == 'searchMe' ? 'btn-warning' : 'btn-primary'
                "
                :disabled="isChangingMode"
                @click="switchMode"
              >
                <i
                  class="material-icons"
                  :class="isChangingMode ? 'fa-spin' : ''"
                ></i>
                {{
                  this.asset.mode == "searchMe"
                    ? $t("data.asset.labels.to_base_mode")
                    : $t("data.asset.labels.to_searchMe_mode")
                }}
              </button>
            </div>
            <div v-if="!isViewingHistory">
              <button class="btn btn-primary" @click="getHistory">
                {{ $t("data.asset.labels.view_history") }}
              </button>
            </div>
            <div
              class="d-flex justify-content-between align-items-baseline"
              v-else
            >
              <button class="btn btn-primary" @click="removeHistory">
                {{ $t("data.asset.labels.remove_history") }}
              </button>
              <label class="bmd-label">Da: </label>
              <input
                type="date"
                class="btn btn-white"
                :max="to"
                :value="from"
                @input="updateFrom"
              />
              <label class="bmd-label">A: </label>
              <input
                type="date"
                class="btn btn-white"
                :max="today"
                :value="to"
                @input="updateTo"
              />
            </div>
            <l-map
              style="height: 35vh"
              class="map"
              :zoom="zoom"
              :center="asset.coordinates.coordinates"
              v-if="!loading"
            >
              <l-tile-layer
                :url="url"
                :attribution="attribution"
              ></l-tile-layer>
              <l-polygon
                :lat-lngs="[herd.geofence.coordinates]"
                :color="herd.hexa_color"
                :fill="false"
              />
              <l-marker
                :lat-lng="asset.coordinates.coordinates"
                v-if="!isViewingHistory && asset.coordinates.coordinates"
              >
                <l-popup>
                  <strong>Nome bovino:</strong>
                  {{ asset.name }} <br />
                  <strong>Data e ora ultima rilevazione: </strong>
                  {{ asset.coordinates.updated_at }} <br />
                  <strong>Latitudine: </strong>
                  {{ asset.coordinates.coordinates[0] }}
                  <br />
                  <strong>Longitudine: </strong>
                  {{ asset.coordinates.coordinates[1] }}
                  <br />
                </l-popup>
              </l-marker>
              <l-marker
                v-else
                v-for="(assetHistoryElement, index) in assetHistory"
                :key="index"
                :lat-lng="assetHistoryElement.coordinates"
              >
                <l-popup>
                  <strong>Nome bovino:</strong>
                  {{ assetHistoryElement.asset.name }} <br />
                  <strong>Data e ora rilevazione: </strong>
                  {{ assetHistoryElement.updated_at }} <br />
                  <strong>Latitudine: </strong>
                  {{ assetHistoryElement.coordinates[0] }}
                  <br />
                  <strong>Longitudine: </strong>
                  {{ assetHistoryElement.coordinates[1] }}
                  <br />
                </l-popup>
                <l-icon
                  class-name="assetHistoryMarkerIcon"
                  :icon-size="dynamicSize"
                  :icon-anchor="dynamicAnchor"
                >
                  <div class="assetHistoryMarkerIcon">
                    {{ index + 1 }}
                  </div>
                </l-icon>
              </l-marker>
              <l-polyline :latLngs="mapHistoryPath" />
            </l-map>
          </div>
        </div>
        <div class="card">
          <div class="card-header card-header-primary card-header-icon">
            <div class="card-icon">
              <i class="material-icons">attachment</i>
            </div>
            <h4 class="card-title">
              Allegati
              <button
                class="float-right btn btn-danger"
                :disabled="isDisabled"
                @click="removeMultipleAttachment"
              >
                {{ buttonText }}
              </button>
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12">
                <ul>
                  <li
                    v-for="attachment in assetAttachment"
                    :key="attachment.uuid"
                  >
                    <input
                      type="checkbox"
                      :id="'category_' + attachment.uuid"
                      @change="imageCheck($event)"
                      :value="attachment.uuid"
                    />
                    <label :for="'category_' + attachment.uuid"
                      ><img
                        class="img-thumbnail"
                        :src="
                          getIcon(attachment.extension, attachment.preview_url)
                        "
                    /></label>
                    <a
                      href="javascript:void(0)"
                      class="mt-5 ml-5"
                      style="color: red"
                      @click="
                        removeAttachment({
                          attachment_id: attachment.uuid,
                        })
                      "
                      ><i class="material-icons">delete</i></a
                    >
                    <a
                      class="mt-5"
                      :href="attachment.original_url"
                      download
                      target="_blank"
                      ><i class="material-icons">remove_red_eye</i></a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12">
                <vue-dropzone
                  ref="myVueDropzone"
                  id="dropzone"
                  :options="dropzoneOptions"
                  v-on:vdropzone-success="uploadSuccess"
                ></vue-dropzone>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import BootstrapAlert from "../../../components/BootstrapAlert.vue";

export default {
  components: {
    vueDropzone: vue2Dropzone,
    BootstrapAlert: BootstrapAlert
  },
  data() {
    return {
      isViewingHistory: false,
      isDisabled: true,
      checkedImages: [],
      // Sets the base time of history view of 1 day before, this can be set by the user before sending data
      from: moment()
        .subtract(1, "days")
        .format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      today: moment().format("YYYY-MM-DD"),
      url:
        "https://api.maptiler.com/maps/hybrid/{z}/{x}/{y}@2x.jpg?key=Cru7v9WzfK586J1qCfLl",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 10,
      iconSize: 15,
      extensionsList: [
        { value: "file-pdf", extension: ["pdf"] },
        { value: "file-word", extension: ["docx", "doc"] },
        { value: "file-excel", extension: ["xsls", "csv", "xls"] },
        { value: "file-common", extension: ["zip", "txt"] }
      ],
      buttonText: this.$t("data.asset.labels.no_attachments_selected"),
      dropzoneOptions: {
        url:
          window.axios.defaults.baseURL +
          "/assets/" +
          this.$route.params.id +
          "/uploadFile",
        thumbnailWidth: 150,
        headers: {
          Authorization:
            "Bearer " + window.localStorage.getItem("user-token") || ""
        },
        init: function() {
          let myDropzone = this;
          myDropzone.on("complete", function(file) {
            myDropzone.removeFile(file);
          });
        }
      }
    };
  },
  beforeDestroy() {
    this.resetState;
  },
  computed: {
    ...mapGetters("AssetsSingle", [
      "asset",
      "herd",
      "assetHistory",
      "assetAttachment",
      "loading",
      "isChangingMode"
    ]),
    mapHistoryPath() {
      let assetHistoryCoordinates = [];
      this.assetHistory.forEach(assetHistoryElement => {
        assetHistoryCoordinates.push(assetHistoryElement.coordinates);
      });
      return assetHistoryCoordinates;
    },
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.1];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.1];
    }
  },
  watch: {
    "$route.params.id": {
      immediate: true,
      handler() {
        this.resetState();
        this.fetchShowData({
          id: this.$route.params.id,
          shouldFetchHerd: true
        });
      }
    }
  },
  methods: {
    ...mapActions("AssetsSingle", [
      "fetchShowData",
      "deleteAttachment",
      "resetState",
      "changeMode",
      "fetchAssetHistory",
      "resetAssetHistory",
      "deleteMultipleAttachment",
      "updateAttachment"
    ]),
    uploadSuccess: function(file, response) {
      if (response.success == true) {
        this.updateAttachment(response.data.attachment);
      }
    },
    removeMultipleAttachment: function() {
      this.$swal({
        title: this.$t("global.are_you_sure"),
        text: this.$t("global.unrevertable_operation"),
        type: "warning",
        showCancelButton: true,
        confirmButtonText: this.$i18n.t("global.delete"),
        cancelButtonText: this.$i18n.t("global.cancel"),
        confirmButtonColor: "#dd4b39",
        focusCancel: true,
        reverseButtons: true
      }).then(result => {
        if (result.value) {
          this.deleteMultipleAttachment(this.checkedImages);
          this.checkedImages = [];
          this.isDisabled = true;
          this.buttonText = this.$t(
            "data.asset.labels.no_attachments_selected"
          );
        }
      });
    },
    removeAttachment: function(attachment_id) {
      this.$swal({
        title: this.$t("global.are_you_sure"),
        text: this.$t("global.unrevertable_operation"),
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
        confirmButtonColor: "#dd4b39",
        focusCancel: true,
        reverseButtons: true
      }).then(result => {
        if (result.value) {
          this.deleteAttachment(attachment_id);
        }
      });
    },
    imageCheck: function(e) {
      if (e.target.checked) this.checkedImages.push(e.target.value);
      else {
        const index = this.checkedImages.indexOf(e.target.value);
        if (index > -1) {
          this.checkedImages.splice(index, 1);
        }
      }
      if (this.checkedImages.length > 0) {
        this.isDisabled = false;
        this.buttonText =
          this.checkedImages.length == 1
            ? this.$t("data.asset.labels.delete_selected_attachment", {
                count: this.checkedImages.length
              })
            : this.$t("data.asset.labels.delete_selected_attachments", {
                count: this.checkedImages.length
              });
      } else {
        this.isDisabled = true;
        this.buttonText = this.$t("data.asset.labels.no_attachments_selected");
      }
    },
    getIcon(ext, path) {
      let icon =
        this.extensionsList.find(item => {
          let { extension } = item;
          if (Array.isArray(extension)) {
            return extension.includes(ext);
          } else if (typeof extension === "string") {
            return extension === ext;
          }
        }) || {};

      return icon.value
        ? window.axios.defaults.publicURL + "images/" + icon.value + ".png"
        : path
        ? path
        : window.axios.defaults.publicURL + "images/file-common.png";
    },
    switchMode() {
      this.changeMode({
        id: this.$route.params.id,
        mode: this.asset.mode == "searchMe" ? "base" : "searchMe"
      });
    },
    updateFrom(newFrom) {
      this.from = moment(newFrom.target.value).format("YYYY-MM-DD");
      this.getHistory();
    },
    updateTo(newTo) {
      this.to = moment(newTo.target.value).format("YYYY-MM-DD");
      this.getHistory();
    },
    setIsViewingHistory(value) {
      this.isViewingHistory = value;
    },
    getHistory() {
      this.fetchAssetHistory({
        id: this.$route.params.id,
        to: this.to,
        from: this.from
      });
      this.setIsViewingHistory(true);
    },
    removeHistory() {
      this.resetAssetHistory();
      this.from = moment()
        .subtract(1, "days")
        .format("YYYY-MM-DD");
      this.to = moment().format("YYYY-MM-DD");
      this.setIsViewingHistory(false);
    }
  }
};
</script>

<style lang="scss" scoped>
.map {
  height: 80%;
  width: 100%;
}

.assetHistoryMarkerIcon {
  background-color: $color-rose;
  color: $white-color;
  border: 1px solid #333;
  border-radius: 20px;
  font-size: auto;
  font-weight: bold;
  box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 20px !important;
  height: 20px !important;
  margin: 0 !important;
}
ul {
  list-style-type: none;
}

li {
  display: inline-block;
}

input[type="checkbox"] {
  display: none;
}

label {
  border: 1px solid #fff;
  padding: 10px;
  display: block;
  position: relative;
  margin: 10px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

label::before {
  background-color: white;
  color: white;
  content: " ";
  display: block;
  border-radius: 50%;
  border: 1px solid grey;
  position: absolute;
  top: -5px;
  left: -5px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 28px;
  transition-duration: 0.4s;
  transform: scale(0);
}

label img {
  height: 100px;
  width: 100px;
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
}

:checked + label {
  border-color: #ddd;
}

:checked + label::before {
  content: "✓";
  background-color: grey;
  transform: scale(1);
}

:checked + label img {
  transform: scale(0.9);
  box-shadow: 0 0 5px #333;
  z-index: -1;
}
</style>
